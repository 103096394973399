.placeholder {
  background-color: #2a2b3b; /* Assuming base-300 is a CSS variable */
  border-radius: 0.75rem;
  will-change: background-position;
  animation: placeholder 1.8s ease-in-out infinite;
  background-image: linear-gradient(
    105deg,
    transparent 0%,
    transparent 40%,
    #353646 50%, /* Assuming base-100 is a CSS variable */
    transparent 60%,
    transparent 100%
  );
  background-size: 200% auto;
  background-repeat: no-repeat;
  background-position-x: -50%;
  opacity: 1;
}

@media (prefers-reduced-motion) {
  .placeholder {
    animation-duration: 15s;
  }
}

@keyframes placeholder {
  from {
    background-position: 150%;
  }
  to {
    background-position: -50%;
  }
}